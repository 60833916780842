import React, { useState } from 'react';
import axios from 'axios';

const apiKey = 'c2a15344-b68d-4ae7-9a4e-5d07b3a1c1ac';

function Login({onLoginSuccess}) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  
  const handle = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    console.log("checkin creds");
    // Create an object with the user's credentials
    const credentials = {
      username,
      password,
    };
    

    // Make an HTTP POST request to your server for authentication
    axios
    .post('https://www.eugipedia.de:1306/login' ,credentials)
    .then((response) => {
      // Check the response for authentication success
      if (response.status === 200 && response.data.token) {
        setAuthenticated(true);
        onLoginSuccess();
      } else {
        alert('Login failed. Please check your credentials.');
      }
    })
    .catch((error) => {
      console.error('Login failed:', error);
      console.error('Response data:', error.response.data); // Log the response data for more information
      alert('Login failed. Please try again.');
    });
    
  }


  return (
    <div className="modal-overlay">
      <div>
        <h2>Login</h2>
        {authenticated ? (
          <p>You are already logged in.</p>
        ) : (
          <form>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={(e) => handle(e)}>Login</button>

          </form>
        )}
      </div>
    </div>
  );
}

export default Login;
