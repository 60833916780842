import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { SketchPicker } from 'react-color';
import './App.css';
import Login from './login';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faCog, faPen } from '@fortawesome/free-solid-svg-icons'; // Import icons from FontAwesome
import { set } from 'mongoose';

const apiKey = 'c2a15344-b68d-4ae7-9a4e-5d07b3a1c1ac';

function App() {
  const [config, setConfig] = useState({
    sliderValue: 38.25,
    colorWheelEnabled: false,
    color: { r: 0, g: 0, b: 0 },
    buttonValues: {
      Static: 0,
      Rainbow: 0,
      Fire: 0,
    },

    MoodValues: {
      Happy: 0,
      Sad: 0,
      Sleepy: 0,
      Mad: 0,
      Stressed: 0,
      Excited: 0,
      InLove: 0,
    },
    selectedColor: { r: 0, g: 0, b: 0 },
  });

  const [showNotification, setShowNotification] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [buttonColor, setButtonColor] = useState(null);
  const [showLogin, setShowLogin] = useState(true);
  const [deviceStatus, setDeviceStatus] = useState({
    deviceH: false, // Initial status
    deviceE: false, // Initial status
  });

  useEffect(() => {
    // Fetch the online status when the component mounts
    async function fetchOnlineStatus() {
      try {
        const response = await axios.get('https://www.eugipedia.de:1306/getOnlineStatus');
        setDeviceStatus(response.data);
      } catch (error) {
        console.error('Error fetching online status', error);
      }
    }

    fetchOnlineStatus();
  }, []);

  const updateConfig = async () => {
    try {
      const updatedStatus = { ...deviceStatus };
      updatedStatus[selectedButton] = config.buttonValues[selectedButton] === 1;
      setDeviceStatus(updatedStatus);
  
      // Exclude "selectedColor" and "buttonValues" from updatedConfig
      const { selectedColor, buttonValues, MoodValues, colorWheelEnabled, customText, switchValue, ...updatedConfig } = config;
      updatedConfig.selectedButton = selectedButton;
  
      const response = await axios.put('https://www.eugipedia.de:1306/update-config', updatedConfig, {
        headers: {
          'Authorization': `Bearer ${apiKey}`
        }
      });

      console.log(response.data);

      // Show the notification when the config is successfully sent
      setShowNotification(true);

      // Automatically hide the notification after 2 seconds
      setTimeout(() => {
        setShowNotification(false);
      }, 2000);
    } catch (error) {
      console.error('Error updating config', error);
    }
  };
  
  const Moods = {
    happy: 'Happy',
    sad: 'Sad',
    excited: 'Excited',
    sleepy: 'Sleepy',
    static: 'Static',
    mad: 'Mad',
    stressed: 'Stressed',
    inLove: 'InLove',
    fire: 'Fire',
    rainbow: 'Rainbow'
  }

  const handleButtonClick = (buttonName) => {
    setSelectedButton('Static');
    setButtonColor(buttonName);
    switch(buttonName){
      case Moods.happy:
        setConfig({...config, color: {r:144, g:144, b:2}, colorWheelEnabled: false})
        break;
      case Moods.sleepy:
        setConfig({...config, color: {r:255, g:15, b:15}, colorWheelEnabled: false})
        break;
      case Moods.mad:
        setConfig({...config, color: {r:255, g:0, b:0}, colorWheelEnabled: false})
        break;
      case Moods.sad:
        setConfig({...config, color: {r:80, g:109, b:227}, colorWheelEnabled: false})
        break;
      case Moods.stressed:
        setConfig({...config, color: {r:139, g:87, b:42}, colorWheelEnabled: false})
        break;
      case Moods.excited:
        setConfig({...config, color: {r:144, g:19, b:254}, colorWheelEnabled: false})
        break;     
      case Moods.inLove:
        setConfig({...config, color: {r:245, g:0, b:175}, colorWheelEnabled: false})
        break;  
      case Moods.fire:
        setSelectedButton('Fire')
        break;  
      case Moods.rainbow:
        setSelectedButton('Rainbow')
        break;  
      case Moods.static:
        setConfig({...config, colorWheelEnabled: true})
        break;
    }


  };
  

  const handleColorChange = (color) => {
    const { r, g, b } = color.rgb; // Extract the RGB values
    setConfig({
      ...config,
      color: { r, g, b }, // Set only the RGB values in the config
    });
  };

  return (
    <div className="App">
      {showLogin ? (
        <Login onLoginSuccess={() => setShowLogin(false)} />
      ) : (
        <div className='container'> 
        <div>
          
          <header>
            <h1>Thinkerly</h1>
            <h2>The distance doesn't define the connection</h2>
            <img src="/connection_header.png" alt="Distance doesn't matter" />
          </header>

          <p></p>
          <input
            type="range"
            min="0"
            max="255"
            value={config.sliderValue}
            onChange={(e) => setConfig({ ...config, sliderValue: e.target.value })}
          />
          <p>Brightness: {Math.round(config.sliderValue/255*100)}%</p>

          
          <p>Mood:</p>
          <div>
            {Object.keys(config.MoodValues).map((buttonName, index) => (
              <button
                key={index}
                onClick={() => handleButtonClick(buttonName)}
                style={{
                  backgroundColor: buttonColor === buttonName ? 'green' : 'red',
                }}
              >
                {buttonName}
              </button>
            ))}
          </div>

          <p>Configurations:</p>
          <div>
            {Object.keys(config.buttonValues).map((buttonName, index) => (
              <button
                key={index}
                onClick={() => handleButtonClick(buttonName)}
                style={{
                  backgroundColor: buttonColor === buttonName ? 'green' : 'red',
                }}
              >
                {buttonName}
              </button>
            ))}
          </div>

          {config.colorWheelEnabled && (
            <div>
              <SketchPicker color={config.color} onChangeComplete={handleColorChange} />
            </div>
          )}

          <div>
            <div className="device-status">
              <div
                className="status-symbol"
                style={{ backgroundColor: deviceStatus.deviceH ? 'green' : 'red' }}
              ></div>
              Hannah's light
            </div>
            <div className="device-status">
              <div
                className="status-symbol"
                style={{ backgroundColor: deviceStatus.deviceE ? 'green' : 'red' }}
              ></div>
              Eugen's light
            </div>
          </div>

          <button onClick={updateConfig}>Update Config</button>

        </div>
        </div>
      )}
      
  </div>
  );
}

export default App;